import { find } from 'lodash';
import { useTestData } from '../util';

export const mapInventoryData = (pricingData: any, inventoryPartCosting: { Sku: string, PurchaseCost: number, UnitPrice: number, Description: string }[]) => pricingData.map((p) => {
  const materialCost = { S: 0, M: 0, L: 0 };
  const price = { S: 0, M: 0, L: 0 };
  const label = p.label;

  if (p.partNumber.S) {
    const sPart = find(inventoryPartCosting, (d) => d.Sku === p.partNumber.S.toString());
    materialCost.S = sPart ? sPart.PurchaseCost : (p.materialCost?.S || 0);
    price.S = sPart ? sPart.UnitPrice : p.price.S;
    // label = sPart ? sPart.Description : label;
  }
  const lPart = find(inventoryPartCosting, (d) => d.Sku === p.partNumber.L.toString());
  materialCost.L = lPart ? lPart.PurchaseCost : (p.materialCost?.L || 0);
  price.L = lPart ? lPart.UnitPrice : p.price.L;
  // label = lPart ? lPart.Description : label;

  const mPart = find(inventoryPartCosting, (d) => d.Sku === p.partNumber.M.toString());
  materialCost.M = mPart ? mPart.PurchaseCost : (p.materialCost?.M || 0);
  price.M = mPart ? mPart.UnitPrice : p.price.M;
  // label = mPart ? mPart.Description : label;

  return {
    ...p, materialCost, price, label,
  };
});

const pricingPaths = {
  BBK: { root: 'body', path: 'bodyWood' },
  GNB: { root: 'neck', path: 'neckWood' },
  BNB: { root: 'neck', path: 'neckWood' },
  GFB: { root: 'neck', path: 'fretboardWood' },
  BFB: { root: 'neck', path: 'fretboardWood' },
};
export const pricingPathFromPartType = (partType: string) => {
  const { root, path } = pricingPaths[partType];
  return `/${root}${useTestData ? 'Test' : ''}/${path}`;
};
