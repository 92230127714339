import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import {
  every,
  find, findIndex, first, includes, noop, uniq,
} from 'lodash';
import { Divider, Modal } from 'antd';
import {
  newBodyAtom, newNeckAtom, partEditModeAtom,
} from 'shared/state/pricingState';
import { DetailRow, PartDetailColumn as ComponentWrapper } from 'shared/styledComponents/containers';
import { PartDetailColumnHeader } from 'shared/styledComponents/typographicElements';
import { PART_BOM_COLLECTION, PART_VIEWER_COLLECTION, partBomItemsAtom } from 'shared/state/partViewState';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import useFirebase from 'vendor/Firebase';
import { currentCustomerAtom } from 'shared/state/customerState';
import { ALL_PART_TERMS, bodyTotal, neckTotal } from 'shared/data';
import DetailTextAreaWithCallback from 'shared/components/Input/DetailTextAreaWithCallback';
import HelpButton from 'shared/components/Utility/HelpButton';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { checkGeometricDifferences, isSamePart, sanitizePartDescription } from 'shared/partParser/util';
import bodyParser from 'shared/partParser/bodyParser';
import neckParser from 'shared/partParser/neckParser';
import { calculateSimilarity } from 'shared/text/description';
import { CustomerMenu } from './index';
import PartNumber from './PartDetailFields/PartNumber';
import PartBomList from './PartBom/PartBomList';
import PartActiveSelector from './PartDetailFields/PartActiveSelector';
import CopyDescriptionButton from '../../Orders/Components/SalesOrderRecord/Buttons/CopyDescriptionButton';
import QuickViewButton from './QuickView/Buttons/QuickViewButton';
import PartInheritanceSelector from './PartDetailFields/PartInheritanceSelector';
import DensityCalcButton from './Buttons/DensityCalcButton';
import PartParentDropdown from './PartDetailFields/PartParentDropdown';
import Loader from '../../../shared/components/Utility/Loader';
import DuplicatePartModal from './DuplicatePartModal';
import PartDotRepresentation from './PartDotRepresentation';
import ActiveSelector from '../../Customer/Components/PriceListInputs/ActiveSelector';
import { userSettingsAtom } from '../../../shared/state/siteState';
import DetailActiveSelectorWithCallback from '../../../shared/components/Input/DetailActiveSelectorWithCallback';
import { IShopOrder } from '../../Orders/types';
import { IOrderItem } from '../../../shared/types/dbRecords';

const PartDetailRow = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  gap: 12px;
`;

const PartNumberWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
    margin-right: 8px;
`;

const PartLineageWrapper = styled(FlexColumn)`
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
`;

const InheritanceSelectorRow = styled(FlexRow)`
    justify-content: flex-start;
    gap: 12px;
`;

const LoaderWrapper = styled(FlexRow)`
    justify-content: flex-start;
    margin-top: 14px;
    gap: 8px;
`;

const PartParentWrapper = styled(FlexRow)`
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
`;
const ParentDescriptionInput = styled(DetailInputWithCallback)`
    margin-top: 2px;
`;

interface IComponent {
  partType: 'body'|'neck';
  partId: string;
  copy: boolean;
  customerParts: any[];
}
const PartDetailColumn = ({
  partType, partId, copy, customerParts = [],
}: IComponent) => {
  const { firestore } = useFirebase();
  const newPartAtom = partType === 'body' ? newBodyAtom : newNeckAtom;
  // @ts-ignore
  const [newPartState, setNewPartState] = useRecoilState(newPartAtom);
  const customer = useRecoilValue(currentCustomerAtom);
  const editMode = useRecoilValue(partEditModeAtom);
  const partBomCollection = useRecoilValue(PART_BOM_COLLECTION);
  const partViewerDataString = useRecoilValue(PART_VIEWER_COLLECTION); 
  const setBomItems = useSetRecoilState(partBomItemsAtom);
  const userSettings = useRecoilValue(userSettingsAtom);

  const totalFunction = partType.match(/body/i) ? bodyTotal : neckTotal;
  const discount = partType.match(/body/i) ? (customer?.bodyDiscount || 0) : (customer?.neckDiscount || 0);
  const [price, setPrice] = useState<number>(0);
  const [bypassConflictCheck, setBypassConfictCheck] = useState<boolean>(false);
  const [_customerParts, setCustomerParts] = useState<any[]>(customerParts);
  const [showDuplicatePartModal, setShowDuplicatePartModal] = useState<boolean>(false);
  const [duplicatePart, setDuplicatePart] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [onLoadingParts, setOnLoadingParts] = useState<boolean>(false);
  const [partDescription, setPartDescription] = useState<string>(newPartState.Description);

  const onCloseDuplicatePartModal = () => {
    setShowDuplicatePartModal(false);
  };

  const onChangeDescription = (update: boolean = false) => async (value: string) => {
    if (update) {
      const sanitizedDescription = sanitizePartDescription(value);
      const parserFunc = partType === 'body' ? bodyParser : neckParser;
      const parsedPartData = parserFunc([[newPartState.active, newPartState.Sku, sanitizedDescription, (newPartState.price || 0).toString()]])[0];

      // Identify the changed term
      const oldTerms = newPartState.Description.split('_');
      const newTerms = sanitizedDescription.split('_');
      let changedTerm = '';
      let oldTerm = '';
      let changedTermIndex = -1;

      for (let i = 0; i < Math.max(oldTerms.length, newTerms.length); i += 1) {
        if (oldTerms[i] !== newTerms[i]) {
          changedTerm = newTerms[i];
          oldTerm = oldTerms[i];
          changedTermIndex = i;
          break;
        }
      }

      // Update the current part
      setPartDescription(sanitizedDescription);
      setNewPartState({ ...newPartState, Description: sanitizedDescription });
      
      if (update) {
        await firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ Description: sanitizedDescription });
      }

      // Update child parts if a term was changed
      if (changedTerm && changedTermIndex !== -1 && newPartState.childParts) {
        const updatePromises = newPartState.childParts.map(async (childSku: string) => {
          const childDoc = await firestore.collection(partViewerDataString).doc(childSku).get();
          if (childDoc.exists) {
            const childData = childDoc.data();
            if (childData) {
              const childTerms = childData.Description.split('_');
              let updated = false;
              const newChildTerms = childTerms.filter((term, index) => {
                const similarity = calculateSimilarity(term, oldTerm);
                if (similarity > 0.8) { // You can adjust this threshold
                  updated = true;
                  return false; // Remove this term
                }
                return true; // Keep this term
              });
              
              if (updated) {
                // Insert the new term at the same index as in the parent, if possible
                if (changedTermIndex < newChildTerms.length) {
                  newChildTerms.splice(changedTermIndex, 0, changedTerm);
                } else {
                  newChildTerms.push(changedTerm);
                }
                
                const newChildDescription = newChildTerms.join('_');
                await firestore.collection(partViewerDataString).doc(childSku).update({ Description: newChildDescription });
              }
            }
          }
        });

        await Promise.all(updatePromises);
      }

      const conflicts = _customerParts
        .filter((p: any) => p)
        .filter((p: any) => p.Sku !== newPartState.Sku)
        .filter((p: any) => {
          const thisSkuValue = parseInt(newPartState.Sku.split('_')[1], 10);
          const thatSkuValue = parseInt(p.Sku.split('_')[1], 10);
          return thisSkuValue > thatSkuValue;
        })
        .filter((p: any) => isSamePart(parsedPartData, p)) || [null];
      const conflict = first(conflicts);
      // conflict = find(_customerParts.filter((p: any) => p.Sku !== newPartState.Sku), (p: any) => p.Description === value);
      if (conflict && !bypassConflictCheck) {
        setDuplicatePart(conflict);
        setShowDuplicatePartModal(true);
      } else {
        let parent = null;
        let childSku = null;
        if (!newPartState.childParts || newPartState.childParts.length === 0) {
          const parentCandidates = _customerParts
            .map((p) => {
              const geoDiff = checkGeometricDifferences(p, parsedPartData);
              if (every(geoDiff)) {
                return p;
              }
              return null;
            }).filter((p) => p && p.Sku !== newPartState.Sku);
          parent = parentCandidates.length === 0 ? null : parentCandidates[0];
          childSku = parentCandidates.length === 0 ? null : `${parentCandidates[0].Sku}.${newPartState.Sku.split('_')[1]}`;
        }
        setNewPartState({
          ...newPartState, Description: sanitizedDescription, parent, childSku,
        });
        if (update) {
          firestore.collection(partViewerDataString).doc(newPartState.Sku || 'noop').get().then((doc) => {
            if (doc.exists) firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ Description: sanitizedDescription, parent: parent?.Sku || null, childSku: childSku || null });
          });
        }
      }
    } else {
      setPartDescription(value);
    }
  };

  const onChangeVolume = async (newVolume: string, update: boolean = false) => {
    setNewPartState({ ...newPartState, volume: newVolume });
    if (!update) return;

    // check to see if the document exists yet. In the case of creating a new part, it will not and we can just bail
    const partDoc = await firestore.collection(partViewerDataString).doc(newPartState.Sku || 'noop').get();
    if (!partDoc.exists) return;

    const updateSkus = [newPartState.Sku];
    /*
    If the document has a parent, we need to update that volume as well, since the parent volume will always override the child volume.
     */
    if (newPartState.parent) updateSkus.push(newPartState.parent.Sku);
    /*
    If the document has children, we need to update each child with the same volume
     */
    if (newPartState.childParts) updateSkus.push(...newPartState.childParts);

    // Update any and all Skus with the new volume
    await Promise.all(updateSkus.map((p: string) => firestore.collection(partViewerDataString).doc(p).get().then((pD) => {
      if (pD.exists) firestore.collection(partViewerDataString).doc(p).update({ volume: newVolume });
    })));
  };

  const onChangeNotes = (update: boolean = false) => (value: string) => {
    setNewPartState({ ...newPartState, notes: value });
    if (update) {
      firestore.collection(partViewerDataString).doc(newPartState.Sku || 'noop').get().then((doc) => {
        if (doc.exists) firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ notes: value });
      });
    }
  };

  const onChangeOneTimeNote = (update: boolean = false) => (value: string) => {
    setNewPartState({ ...newPartState, oneTimeNote: value });
    if (update) {
      firestore.collection(partViewerDataString).doc(newPartState.Sku || 'noop').get().then((doc) => {
        if (doc.exists) firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ oneTimeNote: value });
      });
    }
  };

  const onChangeActive = async (isActive: boolean) => {
    setNewPartState({ ...newPartState, active: isActive });
    if (!isActive && newPartState.childParts) {
      // if we are deactivating a part and it has children, transfer the inheritance to its first child
      const [newParent, ...children] = newPartState.childParts;
      await firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ childParts: null });
      if (newParent) {
        await firestore.collection(partViewerDataString).doc(newParent).update({
          childParts: children,
          childSku: null,
          parent: null,
        });
        await Promise.all(children.map((sku: string) => firestore.collection(partViewerDataString).doc(sku).update({
          parent: newParent,
          childSku: `${newParent}.${sku.split('_')[1]}`,
        })));
      }
    }
  };

  const onChangeInheritance = async (inherits: boolean) => {
    const childSku = inherits ? '' : null;
    const parent = inherits ? '' : null;
    const update = { ...newPartState, childSku, parent } as any;
    const partDoc = await firestore.collection(partViewerDataString).doc(newPartState.Sku).get();
    if (partDoc.exists) await firestore.collection(partViewerDataString).doc(newPartState.Sku).update({ childSku, parent });
    if (inherits && !_customerParts.length) {
      setIsLoading(true);
      const customerPartDocs = await firestore.collection(partViewerDataString)
        .where('customer', '==', newPartState.customer)
        .where('type', '==', newPartState.Description.replace('COPY_', '').substring(0, 2))
        .get();
      setIsLoading(false);
      const parts = customerPartDocs.docs.map((d) => d.data());
      setCustomerParts(parts);
    }
    if (!inherits && newPartState.parent) {
      const parentRecord = newPartState.parent;
      if (!parentRecord) return;

      const childParts = (parentRecord.childParts || []).filter((s) => s !== newPartState.Sku);
      firestore.collection(partViewerDataString).doc(parentRecord.Sku).update({ childParts }).then(() => {
        const newParent = { ...parentRecord, childParts };
        const newParentIndex = findIndex(_customerParts, (p) => p.Sku === parentRecord.Sku);
        const newParts = [..._customerParts];
        newParts[newParentIndex] = newParent;
        setCustomerParts(newParts);
      });
    }
    setNewPartState(update);
  };

  const onChangeParentSku = (parentSku: string) => {
    const parent = find(_customerParts, (p) => p.Sku === parentSku);
    if (!parent) return;
    const childSku = `${parent.Sku}.${newPartState.Sku.split('_')[1]}`;
    if (window.location.href.match(/edit/i)) {
      firestore.collection(partViewerDataString).doc(newPartState.Sku).update({
        childSku,
        parent: parent.Sku,
      }).then(() => {
        const childParts = uniq([...(parent.childParts || []), newPartState.Sku]);
        firestore.collection(partViewerDataString).doc(parent.Sku).update({ childParts }).then(() => {
          setNewPartState({ ...newPartState, childSku, parent });
        });
      });
    } else {
      setNewPartState({ ...newPartState, childSku, parent });
    }
  };

  const onChangeLoad = (type: 'cnc' | 'finishing') => (value: string) => {
    if (type === 'cnc') {
      setNewPartState({ ...newPartState, cncLoad: value });
    } else {
      setNewPartState({ ...newPartState, finishingLoad: value });
    }
  };

  const onChangeRevisionNeeded = async (revisionNeeded: boolean) => {
    setNewPartState({ ...newPartState, revisionNeeded });

    setOnLoadingParts(true);
    const orderDocs = await firestore.collection('orders').where('customer.DisplayName', '==', newPartState.customer).where('completed', '==', false).get();
    const orders = orderDocs.docs.map((o) => o.data() as IShopOrder);

    const orderItemsDocs = await Promise.all(orders.map((o: IShopOrder) => firestore.collection('order-items').doc(o.id).get()));
    const orderItemsData = orderItemsDocs.map((d) => {
      const { orderItems } = d.data() as { orderItems: IOrderItem[] };
      return { id: d.id, orderItems };
    });

    let completed = 0;
    orderItemsData.forEach((o: { id: string, orderItems: IOrderItem[] }) => {
      const items = [...o.orderItems];
      const itemIndex = findIndex(o.orderItems.filter((i) => i), (p) => p.Sku === newPartState.Sku);
      if (itemIndex === -1) {
        completed += 1;
        if (completed === orderItemsData.length) {
          setOnLoadingParts(false);
        }
      } else {
        items[itemIndex] = { ...o.orderItems[itemIndex], revisionNeeded };
        firestore.collection('order-items').doc(o.id).update({ orderItems: items }).then(() => {
          completed += 1;
          if (completed === orderItemsData.length) {
            setOnLoadingParts(false);
          }
        });
      }
    });
  };

  useEffect(() => {
    firestore.collection(partBomCollection).doc(partId).get().then((doc) => {
      if (!doc.exists) return;
      const bomData = doc.data() || { bom: [] };
      setBomItems(bomData.bom);
      const refreshButton = document.getElementById('refresh-jb-items-button');
      if (refreshButton) refreshButton.click();
    });
  }, [newPartState.id]);

  useEffect(() => {
    const total = totalFunction(newPartState) as any;
    const newPrice = Math.round(total.price * (1 + (discount / 100)) + total.discount);
    setPrice(newPrice);
  }, [newPartState]);

  useEffect(() => { 
    setPartDescription(newPartState.Description);
  }, [newPartState.Description]);

  useEffect(() => {
    if (customerParts.filter((p) => p).length === 0) {
      firestore.collection(partViewerDataString).where('customer', '==', newPartState.Sku.split('_')[0]).where('type', '==', newPartState.type).get()
        .then((docs) => {
          setCustomerParts(docs.docs.map((d) => d.data()));
        });
    } else {
      setCustomerParts(customerParts);
    }
  }, [customerParts, newPartState.customerId, newPartState.Sku]);

  return (
    <>
      {duplicatePart !== null && (
        <DuplicatePartModal
          showModal={showDuplicatePartModal}
          currentPart={newPartState}
          duplicatedPart={duplicatePart}
          closeCallback={onCloseDuplicatePartModal}
        />
      )}
      <ComponentWrapper key="part-details-component-wrapper">
        <PartDetailColumnHeader key="part-details-column-header">Part Details</PartDetailColumnHeader>
        <PartDetailRow>
          <CustomerMenu partType={partType} partId={newPartState.Sku || partId} copy={copy} />
          <PartNumberWrapper>
            <PartNumber partType={partType} partId={newPartState.Sku || partId} />
            {partId && (
              <QuickViewButton partId={partId} qboId={newPartState.Id} />
            )}
          </PartNumberWrapper>
          {/* <PartActiveSelector disabled={!editMode} partType={partType} callback={onChangeActive} /> */}
          <DetailActiveSelectorWithCallback
            id="active-selector"
            initialState={newPartState.active}
            callback={onChangeActive}
            disabled={!editMode}
            componentLabel="Status"
            checkedLabel="ACTIVE"
            uncheckedLabel="INACTIVE"
            componentMargin="-16px 0 0 0"
          />
          <DetailActiveSelectorWithCallback
            id="revision-selector"
            initialState={newPartState.revisionNeeded}
            callback={onChangeRevisionNeeded}
            disabled={!editMode}
            componentLabel="Part Revision Needed?"
            checkedLabel="YES"
            uncheckedLabel="NO"
            componentMargin="-16px 0 0 12px"
            LabelExtra={<HelpButton style={{ marginBottom: 2 }} helpMessage="If this part needs any revision before next run, toggle this to YES -- a flag will appear on the order." />}
            async={onLoadingParts}
          />
        </PartDetailRow>
        {/* <PartDotRepresentation terms={ALL_PART_TERMS} input={newPartState.Description} /> */}
        <DetailTextAreaWithCallback
          id="part-details-description-text-area"
          label="Description"
          LabelExtra={<CopyDescriptionButton description={partDescription} />}
          placeholder="e.g., GB_Tele_Vin_S/S_Ash XLite_4Lb 0"
          value={partDescription}
          changeCallback={onChangeDescription(false)}
          blurCallback={onChangeDescription(true)}
          disabled={!editMode}
          height={40}
        />
        <DetailTextAreaWithCallback
          id="part-details-notes-text-area"
          label="Part notes (internal)"
          LabelExtra={(
            <HelpButton
              style={{ marginBottom: 2 }}
              helpMessage="This is a place to add internal notes about a part. Notes will not show up on sales orders."
            />
)}
          placeholder="Add any internal notes here. Notes will not show up on sales orders."
          value={newPartState.notes}
          blurCallback={onChangeNotes(true)}
          changeCallback={onChangeNotes(false)}
          disabled={!editMode}
          height={40}
        />
        <DetailTextAreaWithCallback
          id="part-details-one-time-note-text-area"
          label="OneTime™ note"
          LabelExtra={(
            <HelpButton
              style={{ marginBottom: 2 }}
              helpMessage="Add a one-time note that will convey information for this part on the next order to which it is added."
            />
)}
          placeholder="Add a note for the next order here. This note will self-destruct once this part is added to a new order."
          value={newPartState.oneTimeNote}
          blurCallback={onChangeOneTimeNote(true)}
          changeCallback={onChangeOneTimeNote(false)}
          disabled={!editMode}
          height={40}
        />
        {partType === 'body' && (
          <PartDetailRow>
            <DetailInputWithCallback
              id="part-details-body-volume-input"
              label="Body Volume"
              placeholder="0.00"
              value={newPartState.volume}
              callback={onChangeVolume}
              extend={false}
              isLabel={false}
              disabled={!editMode}
            />
            <DensityCalcButton />
          </PartDetailRow>
        )}
        {partType === 'neck' && (
          <DetailRow>
            {userSettings?.boolean?.showCNCLoad && (
              <ActiveSelector
                key="part-details-cnc-impact-selector"
                activeType={newPartState.cncLoad || 'baby'}
                callback={onChangeLoad('cnc')}
                allValue="Baby"
                activeValue="Mama"
                inactiveValue="Papa"
                label="CNC Impact"
                showLabel
                margin="0 0 18px 0"
                disabled={!editMode}
              />
            )}
            {userSettings?.boolean?.showFinishingLoad && (
            <ActiveSelector
              key="part-details-finishing-impact-selector"
              activeType={newPartState.finishingLoad || 'baby'}
              callback={onChangeLoad('finishing')}
              allValue="Baby"
              activeValue="Mama"
              inactiveValue="Papa"
              label="Finishing Impact"
              showLabel
              margin="0 0 18px 0"
              disabled={!editMode}
            />
            )}
          </DetailRow>
        )}
        {/* <ScopedComponent whitelist={['keithh@wildwoodmfg.com']}> */}
        <PartLineageWrapper>
          <Divider />
          <InheritanceSelectorRow>
            <PartInheritanceSelector
              disabled={!editMode}
              partId={newPartState.Sku}
              childSku={newPartState.childSku}
              callback={onChangeInheritance}
            />
            {isLoading && (
            <LoaderWrapper>
              <Loader label="Loading parts..." radius={24} borderSize={8} />
            </LoaderWrapper>
            )}
          </InheritanceSelectorRow>
          {!includes([undefined, null], newPartState.parent) && (
          <PartParentWrapper>
            <PartParentDropdown
              disabled={!editMode}
              partType={newPartState.Description.substring(0, 2)}
              customerParts={_customerParts}
              partState={newPartState}
              callback={onChangeParentSku}
            />
            <DetailInputWithCallback
              width="400px"
              key="inventory-part-parent-description"
              label="Description"
              placeholder=""
              value={newPartState.parent?.Description}
              callback={noop}
              isLabel
              extend
              disabled
            />
          </PartParentWrapper>
          )}
          <Divider />
        </PartLineageWrapper>
        {/* </ScopedComponent> */}
        <PartBomList
          open
          partType={partType}
          partId={partId || newPartState.Sku}
          partDescription={newPartState.Description}
          partPrice={price}
        />
      </ComponentWrapper>
    </>
  );
};

export default PartDetailColumn;
