import { flatten, flattenDeep } from 'lodash';
import {
  extractSpecies, extractPattern, normalizeTerm, partType, regexMaker, simpleTest, resolveActive,
} from './helpers';
import models from './models';
import neckParser from './neckParser';

const BODY_HEADERS = [
  'WW Part #',
  'Description',
  'Comments/Changes',
  'Qty',
  'Customer Part #',
  'Model',
  'Lefty?',
  'Bridge',
  'BMH?',
  'STH?',
  'Pickups',
  'Control',
  'Core Wood',
  'Top Wood',
  'Top Feature',
  'Finish Weight',
];

const BODY_OBJECT_KEYS = [
  'partNumber',
  'description',
  '',
  '',
  '',
  'model',
  'lefty',
  'bridge',
  'bmh',
  'sth',
  'pickups',
  'control',
  'core',
  'top',
  'bt',
  'weight',
];

export const WOOD_MAP = {
  '5AQuiltMap': ['5AQuiltMap', '5AQuilt', '5A QLT', '5A Quilt', '5AQuilt Map', '5AQltMap'],
  '3AQuiltMap': ['3AQuiltMap', '3AQuilt', '3A QLT', '3A Quilt', '3AQuilt Map', '3AQltMap'],
  '6AQuiltMap': ['6AQuiltMap', '6AQuilt', '6A QLT', '6A Quilt', '6AQuilt Map', '6AQltMap'],
  '5AFlameMap': ['5AFlameMap', '5AFlame', '5A FLM', '5A FLM MAP', '5A FMap', '5A Flame', '5AFlmMap'],
  '6AFlameMap': ['6AFlameMap', '6AFlame', '6A FLM', 'MG FLM', '6A Flame'],
  '3AFlameMap': ['3AFlameMap', '3AFlame', '3A FLM', '3A FLMap', '3A Flame', '3AFlmMap'],
  '5ASpaltedMap': ['5ASpaltMap(?!ed)', '5ASpalt', '5A Spalted', '5A Spalt'],
  VinFlame: ['VinFlameMap', 'Vin Flame', 'VinFlame', 'VinFLM', 'Vin FLM'],
  Basswood: ['basswood'],
  CS: ['customer supplied', 'cs', 'c/s'],
  Alder: ['alder', 'ald', '1Pc Alder', '1 Pc Alder'],
  RstAlder: ['rst alder', 'rstalder'],
  KhyMah: ['kamah', 'khnmah', 'kha mah', 'khamah', 'khayan', 'khymah', 'khmah', 'khy mah', 'kymah', 'kh mah', 'khmah', 'k mah', '1pc khymah', 'kmah', '1pc khmah'],
  Okoume: ['okm', 'okoume'],
  GenMah: ['gen mah', 'genmah', '1Pc genmah', '1 pc genmah'],
  OGRW: ['ogvg rwd', 'redwood', 'ogvg redwood', 'vtg ogr', 'vtg redwood', 'ogr(?!w)', 'ogr vg', 'ogvrw'],
  Paulownia: ['paulownia', 'palownia', 'pawlonia', 'paw'],
  'White Korina': ['wht korina', 'white korina', 'wht limba'],
  'Black Korina': ['BlkKor', 'BlackKor', 'BlkKorina', 'BlackKorina', 'Blk Kor', 'Black Korina', 'Blk Korina', 'black korina', 'blk korina', 'bkor'],
  EWPine: ['ew pine', 'ewp(?!i)', 'ewht pine', 'ewhtpine', 'whtpine', 'e wht pine'],
  RstEWPine: ['rst ew pine', 'rst ewp(?!i)', 'rst whtpine', 'rstwhtpine', 'rstwpine', 'rst wht pine', 'rst ewpine', 'rst ewhtpine', 'rst e wht pine'],
  BlackVen: ['Blk\\s?Ven'],
  SugarPine: ['sugerpine', 'sugarpine', 'sugar pine'],
  'Spalted Maple': ['spaltmaple', 'spalted maple', 'spalt maple', 'spaltedmaple', 'spalt map'],
  Ash: ['swampash', 'swamp ash', 'swash', 'sw ash', 'stained swash', '1Pc swash', 'Fig. swash', 'ash(_\s)'],
  RstAsh: ['rst swash', 'rstswash', 'rstswashlite'],
  Map: ['Maple'],
  MapVen: ['Mpl Ven'],
  PlainMap: ['Plain Map', 'Plain Maple', 'PlainMap', 'PlainMaple'],
  Buckeye: ['Buckeye'],
  HVen: ['Hven'],
  'Black Walnut': ['Black Walnut'],
  Sycamore: ['SYC'],
};

export const BODY_NORMALIZED_TERMS = {
  ...WOOD_MAP,
  WCrv: ['W.?Crv', 'W.?Carve'],
  HCrv: ['H.?Crv', 'H.?Carve'],
  ACrv: ['A.?Crv', 'A.?Carve'],
};

export const WOOD_WEIGHTS = { Lite: ['Lite'], XXLite: ['XXLite'], XLite: ['XLite', '(?<![a-z0-9])XL', '(?<![a-z0-9])XXL', 'X\\sLite', 'Xlite'] };

const bodyWood = (partDescription: string) => {
  const m = regexMaker(flatten(Object.values(WOOD_MAP)), [], true, true);
  const match = partDescription.replace(/\//, '_').match(m);
  if (!match) return [null, null];
  const [core, veneer, top] = flattenDeep([...match].map((w) =>
    w.replace(/_/g, '')
      .replace(' -', '')
      .split('/')
      .map((s) => s.trim())
    // if (veneer) top = top[1];
      .map((t) => extractSpecies(t, WOOD_WEIGHTS))
      .map((t) => {
        if (!t) return null;
        const [wood, weight] = t;
        // const woodString = `${normalizeTerm(WOOD_MAP, wood)} ${weight}`;
        return [normalizeTerm(WOOD_MAP, wood)[0], weight[0]];
      // return woodString.trim();
      }))
    .filter((item) => item));

  if (top === undefined) {
    return [core, '', veneer];
  }
  return [core, veneer, top];
};

const coreWeight = (partDescription: string) => {
  const m = regexMaker(flatten(Object.values(WOOD_WEIGHTS)), [], true, true);
  const match = partDescription.match(m);
  if (!match) return '';
  return match[0].replace(/[\/_\s]/g, '');
};

const model = (partDescription: string) => {
  const terms = partDescription.split('_');
  const rootModel = terms[0];
  
  // Create a regex pattern that matches whole words from the models array
  const modelPattern = new RegExp(`^(${models.join('|')})$`, 'i');
  
  const matchedModels = terms.filter((term) => modelPattern.test(term));
  
  if (matchedModels.length === 0) return 'NO MATCH';
  
  // Use Set to remove duplicates, then convert back to array
  const uniqueMatches = Array.from(new Set(matchedModels));
  
  return [rootModel, ...uniqueMatches].join('_');
};

const pickups = (partDescription: string) => {
  if (partDescription.match(/_nopu_/i)) return ['None', 'None', 'None'];
  const types = [
    'CCH',
    'DC',
    'DMH',
    'DS1',
    'DkStar',
    'DarkStar',
    'DeepS',
    'DualCoil',
    'EMG-35',
    'EMG-40',
    'EMG-45',
    'EMG-MM',
    'EMG-LJV',
    'EMG-SJV',
    'EMGS',
    'H',
    'Hf',
    'HDm',
    "[67]0(')?s\\s?J(\\/)?J",
    'J',
    'LJ',
    'LolH',
    'M',
    'MH',
    'NDC',
    'NPu',
    'P',
    'P90',
    'P90Plastic',
    'RevS',
    'S',
    'SB',
    'SJ',
    'T',
    'T4',
    'T5',
    'TVJ',
    'UniLg',
    'UniMd',
    'UniSm',
    'UNI',
    'WRH',
  ];
  const modifiers = [
    'Rev',
    'Slanted',
    'Baretta',
  ];

  const m = new RegExp(`_((${modifiers.join('|')})\\s)?((${types.join('|')})\\s?[/_])+(\\s|-|_)?`, 'gi');
  // const m = regexMaker(types, modifiers);
  const match = partDescription.match(m);
  if (match === undefined || match === null) return ['', '', ''];
  let [neck, ...[middle, bridge]] = match[match.length - 1].replace(/_/g, '').split('/');

  if (!bridge) {
    if (!middle) {
      bridge = neck;
      neck = 'None';
    } else {
      bridge = middle;
    }
    middle = 'None';
  }

  return [neck, middle, bridge].map((t) => {
    if (!t) return null;
    return t.replace(/[_]|\/$/g, '');
  });
};

const controlType = (partDescription: string) => {
  if (partDescription.match(/fc/i) && partDescription.match(/rc/i)) return 'FC&RC';
  if (partDescription.match(/fc/i)) return 'FC';
  if (partDescription.match(/rc/i)) return 'RC';
  if (partDescription.match(/short\s?control/i)) return 'Short Control';
  return '';
};

const jackType = (partDescription: string) => {
  if (partDescription.match(/sidejack/i)) return 'Side';
  if (partDescription.match(/frontjack/i)) return 'Front';
  if (partDescription.match(/r(ou)?ndjack/i)) return 'Round';
  return '';
};

const bridgeType = (partDescription: string) => {
  const types = [
    'Bigsby',
    '[^_]*HT[^_]*_',
    '[^_]*Trem[^_]*_',
    '[^_]*Floyd[^_]*_',
    'NBR',
    'JM\s?BMH',
    '[^_]*Bridge[^_]*_',
  ];

  const modifiers = [
    'Rev',
  ];

  const m = regexMaker(types, modifiers);
  const match = partDescription.match(m);
  return match ? match[0].replace(/_/g, '').trim() : '';
};

const partWeight = (partDescription: string) => {
  const m = partDescription.match(/([0-9])\s?[l]b[s]?[\s]?([0-9]+)?/i);

  if (!m) return { text: '', value: 0 };

  const [matchString, pounds, ounces, ...rest] = m;

  return { text: m[0].replace(/\sLb/, 'Lb'), value: parseInt(pounds, 10) + ((parseInt(ounces, 10) || 0) / 16) };
};

const construction = (partDescription: string) => {
  const solidMatch = !!partDescription.match(/solid/i);
  const tlMatch = !!partDescription.match(/_TL(\s?FH)?_/);
  const wrMatch = !!partDescription.match(/_wr(hc)?(sh)?_/i);

  return !solidMatch && (tlMatch || wrMatch) ? 'Chambered' : 'Solid';
};

const weightReductionType = (partDescription: string) => {
  const isHoneycomb = !!partDescription.match(/HC_/);
  const isSlot = !!partDescription.match(/WR_/) && !partDescription.match(/HC_/);
  const isTL = !!partDescription.match(/_TL/);
  const isCarveTop = !!partDescription.match(/_CT_/);
  if (isHoneycomb) return 'HC';
  if (isSlot) return 'Slot';
  if (isTL) return 'Cavity';
  if (isCarveTop) return 'CarveTop';
  return 'None';
};

const neckPocket = (partDescription: string) => {
  const match = partDescription.match(/_[^_]+NP_/);
  return match ? match[0].replace(/_/g, '') : '';
};

const roundover = (partDescription: string) => {
  const match = partDescription.match(/_[^_]+RO_/);
  return match ? match[0].replace(/_/g, '') : '';
};

const sanitizeDescription = (description: string): string =>
  // to continue sanitizing, chain "replace" statements onto the last.
  description
    .replace(/"/g, '')
    .replace(/\sLb(s)?/, 'Lb')
    .replace(/60(')?sJ(\/)?J/, '60sJ/J')
    .replace(/70(')?sJ(\/)?J/, '70sJ/J');
export const BODY_PART_HEADERS = [
  'Customer',
  'Part Number',
  'Description',
  'Type',
  'Price',
  'Model',
  'Lefty',
  'Scale',
  'Comfy',
  'Construction',
  'Topped',
];

const heelCarve = (description: string) => {
  let match = description.match(/hcrv/i);
  if (!match) match = description.match(/(crv|jb)heel/i);
  return match ? match[0] : 'N';
};

const blankConstruction = (description: string) => {
  if (description.match(/(1pc)|(1-piece)/i)) return '1Pc';
  if (description.match(/(2Pc off)|(off-center)|(offc)|(off_center)/i)) return '2Pc Off';
  return '2Pc.';
};

const bodyThickness = (description: string) => {
  const thickness = description.match(/((1\.|4)[0-9M]{2,3})/);
  return thickness ? thickness[0] : '1.750';
};

export const parseBodiesFromPartData = (partData: any[]) => {
  const partDetails = partData.map((p) => [p.active ? 'Y' : 'N', p.Sku, p.Description, p.price.toString(), null, null, p.volume || '']);
  return bodyParser(partDetails);
};
const bodyParser = (bodyParts: string[][]) => bodyParts
  .map((l: string[]) => {
    try {
      const [active, partNumber, _description, price, productCode, glCode, volume, parent, revisionNeeded, ...rest] = l;
      const description = _description.replace(/\*/g, '');
      const customer = partNumber.slice(0, 5);
      const type = partType(description);
      const control = controlType(description);
      const jack = jackType(description);
      const bridge = bridgeType(description);
      const [core, veneer, top] = bodyWood(description);
      const scale = extractPattern(description, '[456]/[0-5]{2}(/[0-5]{2})?');
      const battery = extractPattern(description, '[NT]?Batt', 'None');
      const [neckPU, middlePU, bridgePU] = pickups(description);
      const crvHeel = heelCarve(description);
      const thickness = bodyThickness(description);
      const partPrice = price ? parseInt(price.replace('$', ''), 10) : 0;
      const pickupConfig = `${neckPU === 'None' ? '' : neckPU}${middlePU === 'None' ? '' : `${neckPU !== 'None' ? '/' : ''}${middlePU}`}${bridgePU === 'None' ? '' : `${(middlePU !== 'None' || neckPU !== 'None') ? '/' : ''}${bridgePU}`}`;
      const defaultData = {
        active: resolveActive(active),
        // common part attributes
        customer: customer || '',
        Sku: partNumber || '',
        Description: sanitizeDescription(description || ''),
        type: type || '',
        price: partPrice,
        model: model(description),
        core: core ? (core || '') : '',
        top: top ? (top || 'None') : 'None',
        veneer: veneer ? (veneer || 'None') : 'None',
        lefty: simpleTest(description, 'lefty|lft', 'Y', 'N'),
        scale: scale || '',

        // body-specific
        armCarve: simpleTest(description, 'comfy|a(/)?c(rv)?', 'Y', 'N'),
        battery,
        bentTop: simpleTest(description, '_bt_|_dt_', 'Y', 'N'),
        blankConstruction: blankConstruction(description),
        bmh: extractPattern(description, '_[^_]+bmh', ''),
        bridge: bridge || '',
        bridgePickup: bridgePU || '',
        carveTop: simpleTest(description, 'crvtop', 'Y', 'N'),
        comfy: simpleTest(description, 'comfy|(acrv.*wcrv|wcrv.*acrv)', 'Y', 'N'),
        construction: construction(description),
        control: control || '',
        coreWeight: coreWeight(description),
        crvHeel,
        groundWire: simpleTest(description, 'GndWire', 'Y', 'N'),
        jackType: jack || 'N jack',
        middlePickup: middlePU || '',
        neckPickup: neckPU || '',
        neckPocket: neckPocket(description),
        npo: simpleTest(description, 'NPO', 'Y', 'N'),
        neckMountingHoles: simpleTest(description, 'NoNMH', 'N', 'Y'),
        pickups: pickupConfig,
        revisionNeeded: revisionNeeded === 'Y',
        roundover: roundover(description),
        sth: extractPattern(description, '_[^_]+sth', ''),
        switch: simpleTest(description, 'NoSwitch', 'N', 'Y'),
        tra: simpleTest(description, '_tra_', 'Y', 'N'),
        thickness,
        waistCarve: simpleTest(description, 'comfy|w(/)?c(rv)?', 'Y', 'N'),
        weight: partWeight(description),
        weightReductionType: weightReductionType(description),
        wireRout: simpleTest(description, 'NoWireR', 'N', 'Y'),
        volume: volume || '',
      };
      return defaultData;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  });

export default bodyParser;
