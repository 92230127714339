import { atom } from 'recoil';
import { useTestData } from 'shared/util';
import { IInventoryPart } from '../types/dbRecords';
import { qboDateString } from '../data/calendar';

export const inventoryItemsAtom = atom<IInventoryPart[]>({
  key: 'inventoryItemsAtom',
  default: [],
});
export const currentInventoryPageAtom = atom<number>({
  key: 'currentInventoryPageAtom',
  default: (() => {
    const currentPage = sessionStorage.getItem('currentInventoryPage');
    if (currentPage) return parseInt(currentPage, 10);
    return 1;
  })(),
});
export const inventoryListPageSizeAtom = atom<number>({
  key: 'inventoryListPageSize',
  default: 100,
});
export const inventoryListPageCountAtom = atom<number>({
  key: 'inventoryListPageCount',
  default: 1,
});
export const inventoryPartEditedAtom = atom<boolean>({
  key: 'inventoryPartEditedAtom',
  default: false,
});
export const inventorySearchQueryAtom = atom({
  key: 'inventorySearchQueryAtom',
  default: sessionStorage.getItem('inventory.query.string') || '',
});
export const currentInventoryPartAtom = atom<IInventoryPart>({
  key: 'currentInventoryPartAtom',
  default: {
    Name: '',
    Sku: '',
    Description: '',
    Active: true,
    FullyQualifiedName: '',
    Taxable: true,
    UnitPrice: 0,
    Type: 'Inventory',
    IncomeAccountRef: {
      value: '21',
      name: 'Sales Income',
    },
    PurchaseDesc: '',
    PurchaseCost: '',
    ExpenseAccountRef: {
      value: '82',
      name: 'COGS- Raw Materials',
    },
    AssetAccountRef: {
      value: '250',
      name: 'Inventory Asset',
    },
    PrefVendorRef: {
      value: '',
      name: '',
    },
    TrackQtyOnHand: true,
    QtyOnHand: 0,
    ReorderPoint: 0,
    InvStartDate: qboDateString(new Date()),
    TaxClassificationRef: {
      value: 'EUC-09020802-V1-00120000',
      name: 'General taxable retail products - use this if nothing else fits',
    },
    domain: 'QBO',
    Id: '',
    reorderQty: 0,
  } as unknown as IInventoryPart,
});
export const INVENTORY_ITEMS_COLLECTION = atom({
  key: 'INVENTORY_VIEWER_COLLECTION',
  default: `inventory-items${useTestData ? '-dev' : ''}`,
});

export const showQuickEditDrawerAtom = atom<boolean>({
  key: 'showQuickEditDrawerAtom',
  default: false,
});

export const inventoryRequirementsAtom = atom<any[]>({
  key: 'inventoryRequirementsAtom',
  default: [],
});

export const jobRequirementSearchQueryAtom = atom<string>({
  key: 'jobRequirementSearchQueryAtom',
  default: sessionStorage.getItem('jobRequirement.query.string') || '',
});

export const showQuickbooksSyncModalAtom = atom<boolean>({
  key: 'showQuickbooksSyncModalAtom',
  default: false,
});
