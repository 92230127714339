import { atom } from 'recoil';
import { useTestData } from 'shared/util';
import { IBomItem } from '../types/dbRecords';

export const PART_BOM_COLLECTION = atom({
  key: 'PART_BOM_COLLECTION',
  default: `part-bom-data${useTestData ? '-dev' : ''}`,
});
export const PART_VIEWER_COLLECTION = atom({
  key: 'PART_VIEWER_COLLECTION',
  default: `part-viewer-data${useTestData ? '-dev' : ''}`,
});
export const PART_PRICING_COLLECTION = atom({
  key: 'PART_PRICING_COLLECTION',
  default: `part-pricing-data${useTestData ? '-dev' : ''}`,
});
export const BODY_DATA_PATH = `body${useTestData ? 'Test' : ''}`;
export const NECK_DATA_PATH = `neck${useTestData ? 'Test' : ''}`;

export const priceCatalogDataAtom = atom<any>({
  key: 'priceCatalogDataAtom',
  default: {
    body: [],
    neck: [],
  },
});

export const partViewerConfigDataAtom = atom<any>({
  key: 'partViewerConfigDataAtom',
  default: {},
});

export const partViewerTypeAtom = atom<'body'|'neck'>({
  key: 'partViewerTypeAtom',
  default: 'body',
});

export const partViewerStateAtom = atom<any>({
  key: 'partViewerStateAtom',
  default: {
    body: {
      battery: '.*',
      bentTop: '.*',
      bmh: '.*',
      bridge: '.*',
      bridgePickup: '.*',
      carveTop: '.*',
      comfy: '.*',
      construction: '.*',
      control: '.*',
      core: '.*',
      crvHeel: '.*',
      jackType: '.*',
      lefty: '.*',
      middlePickup: '.*',
      model: '.*',
      neckPickup: '.*',
      scale: '.*',
      sth: '.*',
      top: '.*',
      topped: '.*',
      veneer: '.*',
      weight: '.*',
      waistCarve: '.*',
    },
    neck: {
      bound: '.*',
      carve: '.*',
      cnc: '.*',
      fbs: '.*',
      fretCount: '.*',
      fretwire: '.*',
      fretboard: '.*',
      frontInlay: '.*',
      lefty: '.*',
      looseFrets: '.*',
      model: '.*',
      neckCore: '.*',
      nfs: '.*',
      nut: '.*',
      nutType: '.*',
      peghead: '.*',
      radius: '.*',
      revPh: '.*',
      scale: '.*',
      sideInlay: '.*',
      stringCount: '.*',
      tilt: '.*',
      trAccess: '.*',
      trType: '.*',
      vintage: '.*',
    },
  },
});

export const partFilterClearedAtom = atom<number>({
  key: 'PartFilterClearedAtom',
  default: 0,
});

export const activePartDisplayAtom = atom<'active'|'inactive'|'all'>({
  key: 'ActivePartDisplayAtom',
  default: sessionStorage.getItem('activePartDisplayType') || 'all',
});

export const pricedStatusDisplayAtom = atom<'priced'|'unpriced'|'all'>({
  key: 'PricedStatusDisplayAtom',
  default: 'all',
});

export const pricedSortSelectorAtom = atom<'partNumber'|'description'>({
  key: 'pricedStatusSortSelector',
  default: 'partNumber',
});

export const uploadProgressAtom = atom<boolean>({
  key: 'UploadProgressAtom',
  default: false,
});

export const partBomItemsAtom = atom<IBomItem[]>({
  key: 'partBomItemsAtom',
  default: [] as IBomItem[],
});

export const showBomEditorAtom = atom({
  key: 'showBomEditorAtom',
  default: false,
});

// keeps track of the bom item and is used to prompt an update if a bom is edited at the order level.
export const bomEditedAtom = atom<boolean>({
  key: 'bomEditedAtom',
  default: false,
});

export const showBomCopyDrawerAtom = atom({
  key: 'showBomCopyDrawerAtom',
  default: false,
});

export const copiedBomPartNumberAtom = atom<string>({
  key: 'copiedBomPartNumberAtom',
  default: '',
});

export const childViewTypeAtom = atom<'sku'|'like'>({
  key: 'childViewTypeAtom',
  default: sessionStorage.getItem('part.viewer.childViewType') || 'sku',
});
