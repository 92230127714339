import { atom } from 'recoil';
import { useTestData } from 'shared/util';
import { IShopOperator } from '../types/dbRecords';

const transluminousMode = localStorage.getItem('transluminousMode') === 'true';

export const RAW_MATERIALS_COLLECTION = atom({
  key: 'RAW_MATERIALS_COLLECTION',
  default: `rawMaterials${useTestData ? '-dev' : ''}`,
});
export const NON_CONFORMANCE_COLLECTION = atom({
  key: 'NON_CONFORMANCE_COLLECTION',
  default: `non-conformance${useTestData ? '-dev' : ''}`,
});
export const GL_CODES_COLLECTION = atom({
  key: 'GL_CODDES_COLLECTION',
  default: `gl-codes${useTestData ? '-dev' : ''}`,
});
export const PRODUCT_CODES_COLLECTION = atom<string>({
  key: 'PRODUCT_CODES_COLLECTION',
  default: `product-codes${useTestData ? '-dev' : ''}`,
});
export const ROUTER_DATA_PATH = atom({
  key: 'ROUTER_DATA_PATH',
  default: `router${useTestData ? 'Test' : ''}`,
});
export const shopOperatorsObjectAtom = atom <IShopOperator[]>({
  key: 'shopOperatorsAtom',
  default: [],
});

export const currentShopOperatorsListAtom = atom<IShopOperator[]>({
  key: 'currentShopOperatorsListAtom',
  default: [],
});

export const currentShopOperatorAtom = atom({
  key: 'currentShopOperatorAtom',
  default: '',
});

export const userSettingsAtom = atom({
  key: 'userSettingsAtom',
  default: {
    boolean: {
      useTestData: false,
      useWeightedPartsPerDay: false,
      showCNCLoad: false,
      showFinishingLoad: false,
      showDecimalWeights: false,
      showInventoryMargin: false,
    },
    string: {
      scrollToLocation: 'current',
    },
  },
});
