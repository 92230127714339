import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Drawer } from 'antd';
import { useRecoilState } from 'recoil';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWithCallback';
import { camelToTitleCase } from 'shared/text';
import { FlexColumn } from 'shared/containers/FlexContainer';
import { showRealtimeDataDrawerAtom } from 'shared/state/utilState';
import useFirebase from 'vendor/Firebase';

const ComponentWrapper = styled(FlexColumn)`
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
`;
const ComponentHeader = styled.h3`
    font-size: 22px;
`;
const RealtimeDataDrawer = () => {
  const { database } = useFirebase();
  const [realtimeData, setRealtimeData] = useState<{ [key: string]: string|number }>({});
  const [showRealtimeDataDrawer, setShowRealtimeDataDrawer] = useRecoilState(showRealtimeDataDrawerAtom);

  const onPropertyChange = (property: string) => (value: string) => {
    database.ref(`/recordNumbers/${property}`).set(value);
  };

  const onClose = (e: any) => {
    e.preventDefault();
    setShowRealtimeDataDrawer(false);
  };

  useEffect(() => {
    database.ref('/recordNumbers').once('value').then((record) => {
      const data = record.val();
      setRealtimeData(data);
      console.log(data);
    });
  }, []);

  return (
    <Drawer
      placement="right"
      width="25vw"
      open={showRealtimeDataDrawer}
      onClose={onClose}
    >
      <ComponentWrapper>
        <ComponentHeader>Record Numbers</ComponentHeader>
        <>
          {
          Object.entries(realtimeData).map((entry) => {
            const [property, value] = entry;
            const propertyLabel = camelToTitleCase(property);
            return <DetailInputWithCallback key="property-realtime-field" label={propertyLabel} placeholder="" value={value} callback={onPropertyChange(property)} />;
          })
        }
        </>
      </ComponentWrapper>
    </Drawer>
  );
};

export default RealtimeDataDrawer;
