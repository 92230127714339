const models = [
  '49SH',
  '53',
  '12ST',
  '2020 WIDE 34',
  '20F',
  '21 Scale',
  '21F',
  '21F+3 Slots',
  '21FS',
  '22F',
  '22S',
  '22Slots',
  '23F',
  '24.75Scale',
  '24F',
  '24Scale',
  '27.78 Scale',
  '30Scale',
  '34 Scale',
  '34/20',
  '35 Scale',
  '4/21',
  '4/21/24',
  '4/19/30',
  '4/20/24',
  '4/20-WLE',
  '4/20/30',
  '4/20/34',
  '4/21/24',
  '4/21/30',
  '4/21/32',
  '4/21/34',
  '4/21/34-A',
  '4/21/34-M',
  '4/21/34-SA',
  '4/21/40',
  '4/22',
  '4/22/24',
  '4/22/34',
  '4/24',
  '4/24/24',
  '4/24/21',
  '4/24/30.5',
  '4/24/34',
  '4/24/34-SAMT',
  '4/30.5',
  '4ST',
  '4St',
  '5/20',
  '5/20/35',
  '5/20/24',
  '5/20/34',
  '5/20/35',
  '5/21',
  '5/21/24',
  '5/21-WLE',
  '5/21/34',
  '5/21/35',
  '5/21/35-SA',
  '5/22',
  '5/22/24',
  '5/22/34',
  '5/22/34.5',
  '5/22/35',
  '5/24',
  '5/24/24',
  '5/24 SingleCut',
  '5/24/23.5',
  '5/24/34',
  '5/24/34-35',
  '5/24/34-SA',
  '5/24/34.5',
  '5/24/35',
  '50\'s',
  '50s',
  '69TLF Tele',
  '72T',
  '72T Custom',
  '72T DLX',
  'Armitage',
  'B.N. Spec',
  'BBMF',
  'BBMF5',
  'Bass VI',
  'Brawler',
  'BrawlerSC',
  'CALI',
  'CALI-ST',
  'CBS',
  'CHATHAM P',
  'Cabronita',
  'Calavera',
  'Chatham',
  'Chop T-Luxx',
  'Chubby',
  'Coda 4 DLX J',
  'Coda 4 P',
  'Coda 5',
  'Coda 5 DLX J',
  'Coda 5XLite DLX J',
  'DAngelico SC',
  'DoubleCut',
  'Drop Wing',
  'DuoSonic',
  'Esquire',
  'Firebird',
  'GB DoubleCut',
  'GB SingleCut',
  'GB-TFV-TAL',
  'GRAND',
  'GRESHAM J',
  'GRESHAM J5',
  'GROVE',
  'GlnDale',
  'Gresham',
  'Grove ST',
  'IMA',
  'IMA5',
  'J 60s',
  'J 70s',
  'J AMENT',
  'J-4',
  'J-4 WLE',
  'J-421',
  'J-5',
  'J-5 WLE',
  'J-5/24/34-35',
  'J4',
  'J430',
  'J5',
  'J5-20-35',
  'J64',
  'JAMENT',
  'JBAO',
  'JBAO-L',
  'JBO',
  'JBU',
  'JT4',
  'JT5',
  'Jaguar',
  'JazzB',
  'JazzCaster',
  'JazzM',
  'JazzMaster',
  'JazzMaster2PTrem',
  'JazzMasterJZ-RSP',
  'JazzMasterJZMAO',
  'JazzMasterJZMO',
  'JazzMasterLEFTY',
  'JazzMasterNPO',
  'JazzMasterNoTrem',
  'JazzMasterStlTop',
  'JazzMasterTOM',
  'JazzMasterTrem',
  'Junction J',
  'LINCOLN',
  'LKSpecial',
  'LP',
  'LP Jr',
  'LP Jr. DC',
  'LP Tele',
  'LPJr',
  'LPJr DC',
  'LS-13',
  'LS-13 LR',
  'Lap Steel',
  'Lincoln',
  'Lion',
  'Luxx Custom',
  'Lydia Custom',
  'Lynx',
  'Lynx DZ',
  'M4',
  'M4V',
  'M4X',
  'M4XL',
  'M4XLite',
  'M5',
  'M5V',
  'M6',
  'MUSTANG',
  'Mach II',
  'Mach II-JZ',
  'Macon JR',
  'Macon Jr DC',
  'Macon Jr SC',
  'Macon SC',
  'Macon Vintage',
  'Melody Maker',
  'MelodyM',
  'Midwestern',
  'Midwestern2',
  'Mirror',
  'Model-T Tele',
  'Morningstar',
  'Mustang',
  'NoStep',
  'NS 4/5St',
  'NYC',
  'NYC-G1',
  'NYC-G2',
  'NYLN-BRDWY',
  'NYLN-TELE',
  'OFFSET',
  'OS',
  'OS REV PH',
  'OS STD PH',
  'OffSet',
  'Olinto',
  'Olinto J4',
  'Olinto P4',
  'Original MJ',
  'Oz',
  'Oz Cabronita',
  'P BB',
  'P Bass',
  'P-4',
  'P-5',
  'P-5/24/34-35',
  'P4',
  'P4 LG',
  'P5',
  'P51',
  'P52',
  'P52 TeleBass',
  'P54',
  'P55',
  'PBAO',
  'PBO',
  'PJ4',
  'PJ5',
  'PJBAO',
  'PJBU',
  'PT4',
  'Rainmaker',
  'Rainmaker II',
  'Rainmaker II-5St',
  'SATIN',
  'SATIN 24',
  'SB-4',
  'SB-JazzMasterKM6 NP',
  'SB-RPAU',
  'SB-RSP',
  'SBAO',
  'SBAO-1H',
  'SBAO-HT',
  'SBAO-L',
  'SBAO-NPO',
  'SBAO-TL',
  'SBAO-U',
  'SBO',
  'SBO-PN',
  'SC',
  'SEMI-Hollow',
  'SG - Speed Guide',
  'SKY-HAS',
  'SKY-TAL',
  'SM14',
  'SOR',
  'SOR-BB',
  'SOR-BD',
  'STEPPED',
  'STRATHAM 57',
  'SanB1 KM-6',
  'SanB2 KM-6',
  'Semi-Hollow',
  'SingleCut',
  'Solar Arrow',
  'Solar Flight',
  'Solar Sonic',
  'SolarArrow',
  'Springfield Strat',
  'Stepped',
  'StlDeV TiltB',
  'StlDeVJr',
  'StlGuard',
  'StlPhonic TiltB',
  'StlTop',
  'StlTop Deluxe',
  'StlTop TL',
  'Strat',
  'Strat 50s',
  'Strat 57',
  'Strat 60s',
  'Strat 62',
  'Strat 96',
  'Strat Vin',
  'Stratham',
  'Super Strat',
  'T-Rod',
  'T-Strat',
  'T/S',
  'T4',
  'T4/T4',
  'T4/T4T',
  'T5',
  'T5-NR',
  'T5/T5',
  'T69',
  'T72',
  'T72 DLX',
  'T72 Deluxe',
  'TB',
  'TB-RSP',
  'TBAO',
  'TBAO-C',
  'TBAO-CN',
  'TBAO-L',
  'TBAO-NPO',
  'TBAO-TL',
  'TBB-RSP',
  'TBBAO',
  'TBO',
  'TBO-BW',
  'TBO-PN',
  'TBO-TLFM',
  'TC4',
  'TC5',
  'TC63',
  'TC72',
  'TCM-BB',
  'TCP-BD',
  'TEASP-B',
  'TELE',
  'TELE 60s',
  'TELHAM 52T',
  'TEU',
  'TEU Ash XLite',
  'TEU Ash XLiteTE',
  'TEU-LH',
  'TFC',
  'TL',
  'TL 70s',
  'TL 70s Hybrid',
  'TL Ctrl',
  'TL DLX FH',
  'TL DLX NoFH',
  'TL FH',
  'TL Hybrid70s',
  'TL NoFH',
  'TL Strat',
  'TL Tele',
  'TX',
  'TXB4',
  'TXB5',
  'Tele',
  'Tele 3DEG',
  'Tele 60s',
  'Tele 70s Hybrid',
  'Tele Paul',
  'Tele TL',
  'Tele Vin',
  'TeleBaritone',
  'TeleBass',
  'TeleM',
  'TeleMaster',
  'TeleMstr',
  'Telemaster',
  'Telham',
  'Texola TeleM',
  'TopCaster',
  'Truxton',
  'Truxton Tele',
  'Truxton-B Tele',
  'Truxton-L Tele',
  'Truxton-SP Tele',
  'Truxton-TL Tele',
  'Underdog',
  'V4',
  'V5',
  'V52',
  'V52 Snakehead',
  'Verdine',
  'Wayfarer',
  'XJ4',
  'XJ5',
  'XJC4',
  'XS',
  'XS DINKY',
  'XSC',
  'XSC DINKY',
  'XSCREV PH',
  'XSS',
  'XTC'
];

export default models;
