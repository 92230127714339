import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { Button } from 'antd';
import { Refresh, CheckCircle } from '@styled-icons/material-twotone';
import { FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { currentShopOrderAtom } from 'shared/state/orderState';
import useFirebase from 'vendor/Firebase';
import { PART_BOM_COLLECTION, PART_VIEWER_COLLECTION } from 'shared/state/partViewState';
import { IOrderItem } from 'shared/types/dbRecords';
import { partBom } from '../../../../../shared/data/jb_api';

const ComponentWrapper = styled(FlexRow)`
  justify-content: flex-start;
`;

const RefreshIcon = styled(Refresh)`
  width: 24px;
  height: 24px;
  color: ${theme.palette.success.hue};
  transition: color 0.2s ease-in-out;
`;
const DoneIcon = styled(CheckCircle)`
  width: 22px;
  height: 22px;
  margin: 0 0 2px 6px;
  color: ${theme.palette.primary.hue};
`;
const ButtonText = styled.p`
  margin: 0 0 0 4px;
  font-size: 14px;
  line-height: 22px;
  transition: color 0.2s ease-in-out;
`;
const ComponentButton = styled(Button)`
  display: flex;
  border-radius: 8px;
  padding-right: 22px;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${theme.palette.primary.hue};
    & ${RefreshIcon} {
      color: ${theme.palette.neutral.white};
    }
    & ${ButtonText} {
      color: ${theme.palette.neutral.white};
    }
  }
`;

interface IComponent {
  customerId: string;
  orderItems: IOrderItem[];
  callback: (items: IOrderItem[]) => void;
}
const RefreshOrderItemsButton = ({ callback, customerId, orderItems }: IComponent) => {
  const { firestore } = useFirebase();
  const [_orderItems, setOrderItems] = useState<IOrderItem[]>(orderItems);
  const partBomCollectionDbString = useRecoilValue(PART_BOM_COLLECTION);
  const partViewerCollectionDbString = useRecoilValue(PART_VIEWER_COLLECTION);
  const [showWork, setShowWork] = useState(false);

  const onClick = async (e: any) => {
    setShowWork(true);
    const customerPartDocs = await firestore.collection(partViewerCollectionDbString).where('customer', '==', customerId).get();
    const partData = customerPartDocs.docs.map((d) => d.data());
    const updatedItems: IOrderItem[] = [];
    const newItems: IOrderItem[] = [];
    _orderItems.forEach((o: IOrderItem, index) => {
      const part = find(partData, (p: any) => p.Sku === o.Sku);
      if (!part) newItems.push(o);
      else {
        firestore.collection(partBomCollectionDbString).doc(part.Sku).get().then((doc) => {
          if (!doc.exists) updatedItems.push(o);
          else {
            const data = doc.data() as any;
            updatedItems[index] = {
              ...o,
              Sku: part.Sku,
              Description: part.Description,
              unitPrice: data?.price || part.price,
              bom: data?.bom || [],
              cncLoad: part.cncLoad || 'mama',
              volume: part.volume || '',
            };
          }
          if ((updatedItems.length + newItems.length) === orderItems.length) {
            setShowWork(false);
            callback([...updatedItems, ...newItems]);
          }
        });
      }
    });
  };

  useEffect(() => {
    setOrderItems(orderItems);
  }, [orderItems]);
  return (
    <ComponentWrapper key="refresh-jb-items-button-wrapper">
      <ComponentButton type="default" onClick={onClick} key="refresh-jb-items-button">
        <RefreshIcon key="refresh-jb-items-icon" />
        { showWork ? (
          <ButtonText key="refresh-jb-items-button-text-work">Working...</ButtonText>
        ) : (
          <ButtonText key="refresh-jb-items-button-text-refresh">Refresh</ButtonText>
        )}
      </ComponentButton>
    </ComponentWrapper>
  );
};

export default RefreshOrderItemsButton;
