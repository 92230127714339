import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, Divider, Modal, Switch, Radio,
} from 'antd';
import { includes } from 'lodash';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { formatPercent } from 'shared/data';
import useFirebase from 'vendor/Firebase';

const ModalContentWrapper = styled(FlexColumn)`
    justify-content: flex-start;
    align-items: flex-start;
`;

const ModalSyncButton = styled(Button)`
    border-radius: 8px;
`;

const ModalCancelButton = styled(ModalSyncButton)``;

const DatabaseCheckbox = styled(Checkbox)`
    margin-left: 0 !important;
`;

const ModalStepHeader = styled.h3`
    width: 100%;
    color: ${theme.palette.neutral.white};
    background-color: ${theme.palette.primary.hue};
    padding: 2px 4px;
    border-radius: 4px;
`;

const WarningText = styled.h4`
    color: ${theme.palette.warning.D100};
    font-weight: bolder;
    margin: 0;
`;
interface IComponent {
  showModal: boolean;
  closeCallback: (e: any) => void;
}

type SyncDirection = 'prodToDev' | 'devToProd' | 'prodToBackup';

const InventorySyncModal = ({ closeCallback, showModal }: IComponent) => {
  const { firestore, database } = useFirebase();
  const [currentDatabase, setCurrentDatabase] = useState<string>('');
  const [synchronizing, setSynchronizing] = useState<boolean>(false);
  const [processedPercent, setProcessedPercent] = useState<string>('0%');
  const [syncDirection, setSyncDirection] = useState<SyncDirection>('prodToDev');
  const [statusMessage, setStatusMessage] = useState<string>('');

  const databases = [
    'Body Pricing',
    'Bom Data',
    'Customers',
    'Customer Contacts',
    'Customer Shipping Addresses',
    'GL Codes',
    'Inventory',
    'Neck Pricing',
    'Order Items',
    'Orders',
    'Parts',
    'Part Pricing',
    'Product Codes',
    'Record Numbers',
    'Router Steps',
    'Shipments',
    'Vendors',
  ];
  const simpleFirestoreSync = async (databaseKey: string) => {
    let sourceCollection: string;
    let targetCollection: string;

    switch (syncDirection) {
      case 'prodToDev':
        sourceCollection = databaseKey;
        targetCollection = `${databaseKey}-dev`;
        break;
      case 'devToProd':
        sourceCollection = `${databaseKey}-dev`;
        targetCollection = databaseKey;
        break;
      default: 
        sourceCollection = databaseKey;
        targetCollection = `${databaseKey}-bak`;
        break;
    }

    const setProgress = (message: string, percent: number) => {
      setStatusMessage(message);
      setProcessedPercent(`${percent.toFixed(0)}%`);
    };

    const fetchAllDocs = async (collectionName: string) => {
      setProgress(`Fetching ${collectionName} documents`, 0);
      const snapshot = await firestore.collection(collectionName).get();
      setProgress(`Fetched ${collectionName} documents`, 100);
      return snapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {} as Record<string, any>);
    };

    const sourceDocs = await fetchAllDocs(sourceCollection);
    let targetDocs: Record<string, any> = {};
    
    if (syncDirection !== 'prodToBackup') {
      targetDocs = await fetchAllDocs(targetCollection);
    } else {
      // For backup, check if the collection exists
      const backupCollectionRef = firestore.collection(targetCollection);
      const backupCollectionSnapshot = await backupCollectionRef.limit(1).get();
      if (!backupCollectionSnapshot.empty) {
        targetDocs = await fetchAllDocs(targetCollection);
      } else {
        setProgress('Backup collection does not exist. Will create it.', 0);
      }
    }

    setProgress('Comparing documents', 0);
    const updatedDocs: Record<string, any> = {};
    const totalDocs = Object.keys(sourceDocs).length;

    Object.keys(sourceDocs).forEach((docId, index) => {
      const sourceDoc = sourceDocs[docId];
      const targetDoc = targetDocs[docId];

      if (syncDirection === 'prodToDev' || syncDirection === 'prodToBackup') {
        if (!targetDoc || sourceDoc.lastModifiedDate > targetDoc.lastModifiedDate) {
          updatedDocs[docId] = sourceDoc;
        }
      } else {
        updatedDocs[docId] = sourceDoc;
      }

      if (index % 100 === 0) {
        setProgress('Comparing documents', (index / totalDocs) * 100);
      }
    });

    setProgress('Preparing to update documents', 100);

    const batchSize = 500;
    const batches = [];
    const totalUpdates = Object.keys(updatedDocs).length;

    for (let i = 0; i < totalUpdates; i += batchSize) {
      const batch = firestore.batch();
      Object.entries(updatedDocs).slice(i, i + batchSize).forEach(([docId, data]) => {
        const docRef = firestore.collection(targetCollection).doc(docId);
        batch.set(docRef, data);
      });
      batches.push(batch.commit());

      setProgress('Updating documents', (i / totalUpdates) * 100);
    }

    setProgress('Finalizing updates', 90);
    await Promise.all(batches);
    setProgress('Sync completed', 100);
  };

  const simpleRealtimeDbSync = async (databaseKey: string) => {
    const sourceRef = syncDirection === 'prodToDev' ? `/${databaseKey}` : `/${databaseKey}Dev`;
    const targetRef = syncDirection === 'prodToDev' ? `/${databaseKey}Dev` : `/${databaseKey}`;
    
    const dbRef = await database.ref(sourceRef).once('value');
    await database.ref(targetRef).set(dbRef.val());
  };

  const onSelectAll = (e: any) => {
    e.preventDefault();
    setDatabaseSyncList(databases);
  };

  const onDeselectAll = (e: any) => {
    e.preventDefault();
    setDatabaseSyncList([]);
  };

  const databaseMap = {
    Customers: { key: 'customers', func: simpleFirestoreSync },
    'Customer Contacts': { key: 'customer-contacts', func: simpleFirestoreSync },
    'Customer Shipping Addresses': { key: 'customer-shipping-addresses', func: simpleFirestoreSync, divider: true },
    Orders: { key: 'orders', func: simpleFirestoreSync },
    'Order Items': { key: 'order-items', func: simpleFirestoreSync },
    Shipments: { key: 'order-shipments', func: simpleFirestoreSync, divider: true },
    Vendors: { key: 'vendors', func: simpleFirestoreSync },
    Inventory: { key: 'inventory-items', func: simpleFirestoreSync },
    'GL Codes': { key: 'gl-codes', func: simpleFirestoreSync },
    'Product Codes': { key: 'product-codes', func: simpleFirestoreSync },
    'Router Steps': { key: 'router', func: simpleRealtimeDbSync, divider: true },
    Parts: { key: 'part-viewer-data', func: simpleFirestoreSync },
    'Part Pricing': { key: 'part-pricing-data', func: simpleFirestoreSync },
    'Part BOMs': { key: 'part-bom-data', func: simpleFirestoreSync },
    'Body Pricing': { key: 'body', func: simpleRealtimeDbSync },
    'Neck Pricing': { key: 'neck', func: simpleRealtimeDbSync },
    'Record Numbers': { key: 'recordNumbers', func: simpleRealtimeDbSync, divider: true },
  };

  const [databaseSyncList, setDatabaseSyncList] = useState<string[]>(databases);

  interface IDatabaseSyncItem {
    key: string;
    func: (databaseKey: string) => Promise<void>;
  }

  const onSync = async (e: any) => {
    setSynchronizing(true);
    databaseSyncList.reduce(async (previousPromise: Promise<any>, db: string, index: number) => {
      await previousPromise;
      const databaseInfo = databaseMap[db] as IDatabaseSyncItem;
      setCurrentDatabase(`DB ${index + 1} of ${databaseSyncList.length}: ${db}`);
      if (!databaseInfo) return;
      return databaseInfo.func(databaseInfo.key).then(() => {
        const processed = index + 1;
        if (processed === databaseSyncList.length) {
          setSynchronizing(false);
        }
      });
    }, Promise.resolve()); // initial value
  };

  const onCheckDatabase = (db: string) => (e: any) => {
    const newList = e.target.checked ? [...databaseSyncList.filter((s) => s !== db), db] : databaseSyncList.filter((s) => s !== db);
    setDatabaseSyncList(newList);
  };

  const onToggleSyncDirection = (e: any) => {
    setSyncDirection(e.target.value);
  };

  useEffect(() => {
  }, []);

  return (
    <Modal
      onCancel={closeCallback}
      title="Test Database Sync from Live Data"
      open={showModal}
      footer={[
        <ModalCancelButton key="cancel" onClick={closeCallback}>
          Close
        </ModalCancelButton>,
        <ModalSyncButton key="sync" type="primary" loading={synchronizing} onClick={onSync}>
          Sync now
        </ModalSyncButton>,
      ]}
    >
      <ModalContentWrapper>
        {!synchronizing ? (
          <>
            <p>This operation will synchronize data from each of the selected databases below. </p>
            <WarningText>
              <u>WARNING:</u>
              {' '}
              this operation can take a long time, depending on how much synchronization is being performed.
            </WarningText>
            <Divider />
            <FlexRow style={{ gap: 8, alignItems: 'center', marginBottom: '16px' }}>
              <span>Sync Direction:</span>
              <Radio.Group onChange={onToggleSyncDirection} value={syncDirection}>
                <Radio.Button value="prodToDev">Prod to Dev</Radio.Button>
                <Radio.Button value="devToProd">Dev to Prod</Radio.Button>
                <Radio.Button value="prodToBackup">Backup Prod</Radio.Button>
              </Radio.Group>
            </FlexRow>
            <FlexRow style={{ gap: 8 }}>
              <ModalSyncButton type="primary" onClick={onSelectAll}>Check All</ModalSyncButton>
              <ModalSyncButton type="default" onClick={onDeselectAll}>Uncheck All</ModalSyncButton>
            </FlexRow>
            <h4>Select databases to sync</h4>
            <>
              {Object.entries(databaseMap).map((e: any) => {
                const [dbKey, dbValue] = e;
                return (
                  <>
                    <DatabaseCheckbox onChange={onCheckDatabase(dbKey)} checked={includes(databaseSyncList, dbKey)}>{dbKey}</DatabaseCheckbox>
                    {dbValue.divider && (
                      <Divider />
                    )}
                  </>
                );
              })}
            </>
          </>
        ) : (
          <>
          <h3>{`Synchronizing ${currentDatabase}...${processedPercent} complete`}</h3>
          <p>{statusMessage}</p>
          </>
        )}
      </ModalContentWrapper>
    </Modal>
  );
};

export default InventorySyncModal;
