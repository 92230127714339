import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import useFirebase from 'vendor/Firebase';
import {
  every, includes, find, flattenDeep, sortBy, uniqBy,
} from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  bodyDataAtom,
  currentCustomerPrices,
  dataLoadingAtom, neckDataAtom, priceSearchQueryAtom, useTestDataAtom,
} from 'shared/state/pricingState';
import { discountPrice, fetchPricing } from 'shared/data';
import { FlexColumn, FlexRow } from 'shared/containers/FlexContainer';
import theme from 'shared/theme';
import { ICustomerRecord } from 'shared/types/dbRecords';
import {
  adminUserEmailsAtom,
  customerUserEmailsAtom, officeUserEmailAtom,
  superAdminUserEmailAtom,
  supervisorEmailsAtom,
} from 'shared/state/routingState';
import { currentPageTitleAtom } from 'shared/state/orderState';
import Loader from 'shared/components/Utility/Loader';
import {
  childViewTypeAtom,
  PART_PRICING_COLLECTION,
  PART_VIEWER_COLLECTION,
  pricedSortSelectorAtom,
} from 'shared/state/partViewState';
import { devLog } from 'shared/util/logging';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { PricingCol, PageHeaderWrapper } from '../styledComponents';
import { PriceList } from './Components';
import AdminInputs from './Components/PriceListInputs/AdminInputs';
import CustomerMenu from './Components/CustomerMenu';
import ScopedComponent from '../../shared/components/Utility/ScopedComponent';
import ItemListFilter from '../../shared/components/Input/ItemListFilter';
import PricedStatusSelector from './Components/PriceList/Components/PricedStatusSelector';
import ExportPricingButton from './Components/Buttons/ExportPricingButton';
import { currentCustomerAtom, CUSTOMERS_DB_COLLECTION, customersAtom } from '../../shared/state/customerState';
import PriceSortSelector from './Components/PriceSortSelector';
import {
  AdminToolsButtonWrapper, AdminToolsFilterWrapper, PageAdminToolsWrapper,
} from '../../shared/styledComponents/containers';
import { PageTitle } from '../../shared/styledComponents/typographicElements';
import PartActiveSelector from './Components/PriceList/Components/PartActiveSelector';
import { listFilterQuery } from '../../shared/util';
import { cleanMeta, escapeMeta } from '../../shared/text';

const CustomerSiteHeader = styled(PageAdminToolsWrapper)`
  flex-direction: row;
  width: 100%;
  gap: 12px;
`;

const CustomerSelectWrapper = styled(FlexRow)`
    margin-left: 72px;
    gap: 12px;
    
    @media ${theme.device.laptopL} {
      margin-left: 0;
    }
`;

const ActionButtonRow = styled(FlexRow)`
  height: 48px;
  gap: 12px;
  align-items: flex-end;
`;
/**
 * Display component composing the right-hand side of the "Customer Pricing" page
 *   Customer details, priced items, priced items filtering
 */
export default () => {
  const { firestore } = useFirebase();
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useRecoilState(dataLoadingAtom);
  const customerId = localStorage.getItem('currentCustomerId') || null;
  const customers = useRecoilValue(customersAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const setCurrentPageTitle = useSetRecoilState(currentPageTitleAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const officeUserEmails = useRecoilValue(officeUserEmailAtom);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const supervisorEmails = useRecoilValue(supervisorEmailsAtom);
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const [pricing, setPricing] = useRecoilState(currentCustomerPrices);
  const bodyData = useRecoilValue(bodyDataAtom);
  const neckData = useRecoilValue(neckDataAtom);
  const searchLikePartsString = useRecoilValue(priceSearchQueryAtom);
  const partCollectionDBString = useRecoilValue(PART_VIEWER_COLLECTION);
  const partPricingCollectionDBString = useRecoilValue(PART_PRICING_COLLECTION);
  const customerCollectionDbString = useRecoilValue(CUSTOMERS_DB_COLLECTION);
  
  const sessionQueryKey = 'pricing.query.string';
  const sessionActiveKey = 'pricing.showActive';
  const sessionPageKey = 'pricing.currentPage';
  const childViewKey = 'part.viewer.childViewType';
  const sortKey = 'part.viewer.sortOrder';
  const [renderItems, setRenderItems] = useState<any>([]);
  const [childViewType, setChildViewType] = useState<'sku'|'like'>(localStorage.getItem(childViewKey) || 'sku');
  const [showActive, setShowActive] = useState<'all'|'active'|'inactive'>(sessionStorage.getItem(sessionActiveKey) || 'active');
  const [sortOrder, setSortOrder] = useState<'sku'|'description'|'like'>(localStorage.getItem(sortKey) || 'sku');
  const [showPriceType, setShowPriceType] = useState<'all'|'priced'|'unpriced'>('all');
  const [pricingSearchQuery, setPricingSearchQuery] = useState<string>(sessionStorage.getItem(sessionQueryKey) || '');
  const [currentPage, setCurrentPage] = useState<number>(parseInt((sessionStorage.getItem(sessionPageKey) || '1'), 10));
  
  const isPartIdForCurrentCustomer = (partId: string | null, customerDisplayName: string): boolean => {
    if (!partId) return false;
    return new RegExp(customerDisplayName).test(partId);
  };

  const updatePageData = (activeStatus: string, priceType: string, searchQuery: string, _sortOrder: 'sku'|'description'|'like'): void => {
    const _query = listFilterQuery(searchQuery);
    const discountedPricing = pricing.map((p: any) => ({
      ...p,
      discountedPrice: discountPrice(p, currentCustomer),
      parent: find(pricing, (i: any) => i.Sku === p.parent) || null,
    }));
    const _pricing = sortBy(discountedPricing, (p: any) => {
      if (_sortOrder === 'description') return p.Description;
      if (_sortOrder === 'like') return p.childSku || p.Sku;
      return p.Sku;
    });
    const _renderItems = _pricing
      .filter((p: any) => {
        if (activeStatus === 'active') return p.active;
        if (activeStatus === 'inactive') return !p.active;
        return true;
      })
      .filter((p: any) => {
        if (priceType === 'priced') return p.pricing;
        if (priceType === 'unpriced') return !p.pricing;
        return true;
      })
      .filter((p: any) => {
        if (!_query.length) return p;
        const matches = _query.map((t: string) => {
          const childSkuMatchingTerm = _sortOrder === 'like' ? (p.childSku || '') : '';
          const matchText = cleanMeta(`${escapeMeta(p.Description).replace(/\s/g, '')}${childSkuMatchingTerm}${p.Sku}`);
          if (t.match('not')) {
            const matcher = t.replace('not', '');
            return !matchText.match(new RegExp(matcher.trim(), 'i'));
          }
          if (t.match('\bor\b')) {
            const matcher = t.replace(' or ', '|');
            return matchText.match(new RegExp(matcher.trim(), 'i'));
          }
          if (t.length > 0 && 'body'.includes(t.toLowerCase())) return matchText.match(/[gb]b/i);
          if (t.length > 0 && 'neck'.includes(t.toLowerCase())) return matchText.match(/[gb]n/i);
          return matchText.match(new RegExp(t.trim(), 'i'));
        });
        return every(matches, Boolean);
      });
    setRenderItems(_renderItems);
  };

  const customerPricing = async (customer: ICustomerRecord, pricingData = [bodyData, neckData], childView = childViewType) => {
    setLoading(true);
    if (customer.id) {
      const customerPrices = await fetchPricing(firestore, partCollectionDBString, partPricingCollectionDBString, customer, pricingData, childView);
      setPricing(customerPrices);
      setLoading(false);
    }
  };

  const onChangeSortOrder = (order: 'sku'|'description'|'like') => {
    setSortOrder(order);
    localStorage.setItem(sortKey, order);
    setChildViewType(order === 'like' ? 'like' : 'sku');
    updatePageData(showActive, showPriceType, pricingSearchQuery, order);
  };

  const onPercentChange = (bodyDiscount: number, neckDiscount: number) => {
    const updatedCustomer = { ...currentCustomer, bodyDiscount, neckDiscount };
    firestore.collection(customerCollectionDbString).doc(updatedCustomer.DisplayName).update({ bodyDiscount, neckDiscount }).then(() => {
      setCurrentCustomer(updatedCustomer);
      customerPricing(updatedCustomer);
    });
  };

  const onLockChange = (locked: boolean) => {
    const updatedCustomer = { ...currentCustomer, adjustLock: locked };
    firestore.collection(customerCollectionDbString).doc(updatedCustomer.DisplayName).update({ adjustLock: locked }).then(() => {
      setCurrentCustomer(updatedCustomer);
    });
  };

  const onPartFilterChange = (value: string) => {
    const sessionQuery = sessionStorage.getItem(sessionQueryKey) || '';
    setPricingSearchQuery(value);
    if (sessionQuery !== value) {
      sessionStorage.setItem(sessionQueryKey, value);
      sessionStorage.setItem(sessionPageKey, '1');
      setCurrentPage(1);
    }
    updatePageData(showActive, showPriceType, value.replace(/\bor\b/g, '|').replace(/\s/g, ''), sortOrder);
  };

  const onChangeActiveSelector = (value: 'all'|'active'|'inactive') => {
    setShowActive(value);
    sessionStorage.setItem(sessionActiveKey, value);
    updatePageData(value, showPriceType, pricingSearchQuery, sortOrder);
  };

  const onChangePricedSelector = (value: 'all'|'priced'|'unpriced') => {
    setShowPriceType(value);
    updatePageData(showActive, value, pricingSearchQuery, sortOrder);
  };

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
    sessionStorage.setItem(sessionPageKey, newPage.toString());
  };

  const onChangeChildView = async (value: 'sku' | 'like') => {
    setChildViewType(value);
    localStorage.setItem(childViewKey, value);
    // await customerPricing(currentCustomer, [bodyData, neckData], value);
    updatePageData(showActive, showPriceType, pricingSearchQuery, sortOrder);
  };

  useEffect(() => {
    if (currentCustomer.id === '') {
      devLog('CustomerPriceList', 154, 'finding customer based on local storage value for customer');
      const customer = find(customers, (c: ICustomerRecord) => c.id === customerId);
      if (customer) {
        setCurrentCustomer(customer);
        customerPricing(customer);
      }
    } else {
      devLog('CustomerPriceList', 154, `fetching pricing for ${currentCustomer.DisplayName}`);
      const urlParams = new URLSearchParams(window.location.search);
      const partId = urlParams.get('partId');
      if (partId) {
        if (!isPartIdForCurrentCustomer(partId, currentCustomer.DisplayName)) {
          setCurrentPage(1);
        }
      } else {
        setCurrentPage(1);
      }
      customerPricing(currentCustomer);
    }
  }, [currentCustomer.id, currentCustomer.bodyDiscount, currentCustomer.neckDiscount]);

  useEffect(() => {
    updatePageData(showActive, showPriceType, pricingSearchQuery, sortOrder);
  }, [pricing]);

  useEffect(() => {
    const updatedQuery = searchLikePartsString.split('::')[0];
    onPartFilterChange(updatedQuery);
  }, [searchLikePartsString]);

  return (
    <PageHeaderWrapper key="customer-pricing-pricing-wrapper">
      <ScopedComponent whitelist={customerEmails.emails} key="customer-pricing-customer-scope">
        <CustomerSiteHeader key="customer-pricing-customer-site-header">
          <ItemListFilter
            id="customer-pricing-customer-price-filter"
            callback={onPartFilterChange}
            currentValue={pricingSearchQuery}
            prompt="Filter parts using comma-separated terms, e.g., IRW, 55090"
          />
          <ActionButtonRow>
            <PriceSortSelector sortCallback={onChangeSortOrder} sortOrder={sortOrder} />
            <PartActiveSelector key="customer-pricing-admin-active-status-selector" currentValue={showActive} callback={onChangeActiveSelector} />
            <ExportPricingButton key="customer-pricing-export-pricing-button" style={{ width: 152 }} isCustomer />
          </ActionButtonRow>
        </CustomerSiteHeader>
      </ScopedComponent>
      <ScopedComponent blacklist={customerEmails.emails} key="customer-pricing-admin-scope">
        <PageAdminToolsWrapper id="customer-admin-inputs" key="customer-pricing-pricing-admin-wrapper">
          <AdminToolsButtonWrapper key="customer-pricing-admin-only-tools">
            <CustomerSelectWrapper>
              <PageTitle>Pricing</PageTitle>
              <CustomerMenu key="customer-pricing-customer-menu" showLabel={false} />
              <ScopedComponent whitelist={[...superAdminEmails.emails, ...adminEmails.emails]} key="customer-pricing-admin-scope">
                <AdminInputs
                  key="customer-pricing-admin-inputs"
                  lockChangeCallback={onLockChange}
                  percentChangeCallback={onPercentChange}
                  bodyDiscount={currentCustomer.bodyDiscount}
                  neckDiscount={currentCustomer.neckDiscount}
                  locked={currentCustomer.adjustLock}
                />
              </ScopedComponent>
            </CustomerSelectWrapper>
          </AdminToolsButtonWrapper>
          <AdminToolsFilterWrapper key="customer-pricing-filter-wrapper">
            <ItemListFilter
              id="customer-pricing-customer-price-filter"
              callback={onPartFilterChange}
              currentValue={pricingSearchQuery}
              prompt="Filter parts using comma-separated terms, e.g., IRW, 55090"
            />
            <PricedStatusSelector key="customer-pricing-admin-priced-status-selector" currentValue={showPriceType} callback={onChangePricedSelector} />
            <PartActiveSelector key="customer-pricing-admin-active-status-selector" currentValue={showActive} callback={onChangeActiveSelector} />
            <ScopedComponent whitelist={officeUserEmails.emails}>
              <PriceSortSelector sortCallback={onChangeSortOrder} sortOrder={sortOrder} />
            </ScopedComponent>
          </AdminToolsFilterWrapper>
        </PageAdminToolsWrapper>
      </ScopedComponent>
      <PricingCol key="customer-pricing-pricing-column">
        {loading
          ? (
            <>
              <FlexColumn key="customer-pricing-loader-wrapper" style={{ width: '100%', height: '50vw' }}>
                <Loader key="customer-pricing-loader" />
              </FlexColumn>
            </>
          ) : (
            <>
              <PriceList renderItems={renderItems} currentPage={currentPage} pageChangeCallback={onChangePage} childViewType={sortOrder} />
            </>
          )}
      </PricingCol>
    </PageHeaderWrapper>
  );
};
