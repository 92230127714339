import React, { useContext, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { keyframes } from 'styled-components';
import { Button, Modal } from 'antd';
import { every, findIndex, omit } from 'lodash';
import { ArrowDropDown } from '@styled-icons/material';
import theme from 'shared/theme';
import {
  INVENTORY_ITEMS_COLLECTION,
  currentInventoryPartAtom,
  inventoryItemsAtom,
  showQuickEditDrawerAtom, inventoryPartEditedAtom,
} from 'shared/state/inventoryState';
import {
  clearRedirect, popRedirect, redirect, useTestData,
} from 'shared/util';
import QBOInventoryAdjustment from 'shared/data/QBO/inventoryAdjustment';
import QBOItem from 'shared/data/QBO/item';
import { IItemAdjustment, IQBOItem, IQuantityAdjustmentItem } from 'shared/types/qbo';
import { IInventoryChild, IInventoryPart } from 'shared/types/dbRecords';
import { qboDateString } from 'shared/data/calendar';
import { SaveText, SpinnerWrapper, SaveSpinner } from 'shared/styledComponents/utility';
import useFirebase from 'vendor/Firebase';
import { reorderPointNotification } from 'shared/messaging';
import { MESSAGE_DB_PATH_ATOM, reorderPointNotificationUsersAtom } from '../../../../shared/state/messageState';
import { AuthContext } from '../../../../vendor/Firebase/AuthProvider';

/**
 * Button component that is responsible for saving inventory records
 */

const DropdownIcon = styled(ArrowDropDown)`
  width: 24px;
  margin-bottom: 2px;
`;

const SaveButton = styled(Button)`
  margin-left: ${theme.spacing(3)};
  background-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.success[900])} !important;
  border-color: ${(props: any) => (props.testMode ? theme.palette.error.D500 : theme.palette.neutral.white)};
  border-radius: ${theme.spacing(1)};
  border: none;
  color: ${theme.palette.neutral.white};

  &:hover {
    color: ${theme.palette.neutral.white};
    background-color: ${(props: any) => (props.testMode ? theme.palette.error.hue : theme.palette.success.D100)} !important;
    border-color: transparent;
  }
`;
export default () => {
  const { currentUser } = useContext(AuthContext);
  const { firestore, database } = useFirebase();
  const partState = useRecoilValue(currentInventoryPartAtom);
  const [quickEditMode, setQuickEditMode] = useRecoilState(showQuickEditDrawerAtom);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const setInventoryPartEdited = useSetRecoilState(inventoryPartEditedAtom);
  const reorderItemNotificationUsers = useRecoilValue(reorderPointNotificationUsersAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const [inventoryItems, setInventoryItems] = useRecoilState(inventoryItemsAtom);
  // @ts-ignore
  const [isSaving, setIsSaving] = useState(false);
  const [saveText, setSaveText] = useState('Saving to QBO...');

  const finishSave = () => {
    setIsSaving(false);
    setInventoryPartEdited(false);
    if (quickEditMode) {
      setQuickEditMode(false);
    } else if (window.location.href.match(/copy/)) {
      localStorage.removeItem('inventory.temp.part');
      const redirectUrl = popRedirect();
      if (redirectUrl && !redirectUrl.match(new RegExp(partState.Sku))) {
        clearRedirect();
        window.location.href = `/inventory?partId=${partState.Sku}`;
      }
    } else {
      redirect();
    }
  };

  const updateParent = async () => {
    const parentRecord = await firestore.collection(inventoryDbString).doc(partState.Parent?.Sku).get();
    const parentData = parentRecord.data() as IInventoryPart;
    const parentChildren = parentData.Children;
    const childSkus = parentChildren.filter((c: IInventoryChild) => c.sku !== partState.Sku).map((c) => c.sku);
    const childDocs = childSkus.length ? await Promise.all(childSkus.map((sku: string) => firestore.collection(inventoryDbString).doc(sku).get())) : { docs: [] };
    // @ts-ignore
    const childData = childSkus.length ? [partState, ...childDocs.map((d) => d.data())] : [partState];

    const quantityAvailable = childData.map((c: IInventoryPart) => {
      const factor = (c.Parent?.ParentPerChild || 1) >= 1 ? (c.Parent?.ParentPerChild || 1) : (1 / (c.Parent?.ParentPerChild || 1));
      return Math.round(c.QtyOnHand * factor);
    }).reduce((a, b) => a + b, 0);
    // average cost is determined solely by the average of the unit cost of each child, without respect to available quantity
    const averageCost = childData
      .map((c: IInventoryPart) => {
        const factor = (c.Parent?.ParentPerChild || 1) >= 1 ? (c.Parent?.ParentPerChild || 1) : (1 / (c.Parent?.ParentPerChild || 1));
        // @ts-ignore
        return c.PurchaseCost / factor;
      })
      .reduce((a, b) => a + b, 0) / childData.length;

    /*
      Enable this once we are saving/editing quantities in QBO
     */
    const qboParentPart = QBOItem.fromPart(parentData, partState.InvStartDate ? new Date(`${partState.InvStartDate}T07:00:00`) : new Date());
    await QBOItem.update({ ...qboParentPart, QtyOnHand: 0, PurchaseCost: parseFloat(averageCost.toFixed(2)) }) as IQBOItem;
    await firestore.collection(inventoryDbString).doc(parentData.Sku).update({ PrefVendorRef: null, QtyOnHand: quantityAvailable, PurchaseCost: parseFloat(averageCost.toFixed(2)) });
  };
  const saveChild = async (inventoryState: IInventoryPart) => {
    const parentRecord = await firestore.collection(inventoryDbString).doc(inventoryState.Parent?.Sku).get();
    const parentData = parentRecord.data() || { Children: [] };
    const children = parentData.Children || [];
    const childIndex = findIndex(children, (c: { sku: string, id: string }) => c.sku === inventoryState.Sku);
    if (childIndex >= 0) return;
    const child = { sku: inventoryState.Sku, id: inventoryState.Id };
    await firestore.collection(inventoryDbString).doc(inventoryState.Parent?.Sku).update({ Children: [...children, child] });
  };

  const onSave = async (e: any) => {
    // Validation: part cannot be saved unless passing these checks
    if (partState.Description === '') {
      Modal.error({
        title: 'No Description!',
        content: 'All parts must have a description to be saved',
      });
      return;
    }
    if (partState.Sku === '') {
      Modal.error({
        title: 'No Part Number!',
        content: 'All parts must have a part number to be saved',
      });
      return;
    }
    if (partState.ProductCode.productCode === '') {
      Modal.error({
        title: 'No Product Code!',
        content: 'All parts must have a product code to be saved',
      });
      return;
    }
    // END VALIDATION

    setIsSaving(true);
    let updated;
    let qboRecord;

    // only save to QBO if we are not in test mode
    if (!useTestData) {
      setSaveText('Saving to QBO...');
      // if we are copying the part, we do not want any quantity to be saved to QBO
      const qtyOnHand = partState.Children || window.location.href.match('copy=true') ? 0 : partState.QtyOnHand;
      const qboItem = QBOItem.fromPart({
        ...partState,
        QtyOnHand: qtyOnHand,
      }, partState.InvStartDate ? new Date(`${partState.InvStartDate}T07:00:00`) : new Date());
      // await QBOAuth.refreshToken(database);
      // fetch item from QBO
      qboRecord = await QBOItem.fetchByName(partState.Sku) as IQBOItem;

      /*
        To create/update the part in QBO:
        IF the part doesn't exist (!qboRecord) from above line, use the create API call to make the item in QBO.
        IF the part DOES exist, update it but omit the QtyOnHand property so it uses the same value as it has in QBO.
          THEN use the inventory Adjustment API to make the adjustment to the quantity in QBO.
       */
      if (!qboRecord) {
        updated = await QBOItem.create(omit(qboItem, ['Id'])) as IQBOItem;
      } else {
        updated = await QBOItem.update({
          ...omit(qboItem, ['QtyOnHand']),
          QtyOnHand: qboRecord.QtyOnHand || 0,
        }) as IQBOItem;
        const inventoryAdjustment = await QBOInventoryAdjustment.itemAdjustmentFromParts(
          [{ ...qboItem, adjustmentQuantity: qboItem.QtyOnHand - (qboRecord.QtyOnHand || 0) } as IQuantityAdjustmentItem],
          null,
          partState.InvStartDate ? new Date(partState.InvStartDate) : new Date(),
          `${qboItem.Sku} - quantity updated manually by ${currentUser.email.split('@')[0]}`,
        ) as IItemAdjustment;
        // Finally, create the inventory adjustment
        await QBOInventoryAdjustment.create(inventoryAdjustment);
      }
    }

    // If record already exists in QBO: Create inventory adjustment object - subtract HELM quantity from current quantity in QBO
    // if (qboRecord) {
    //   const inventoryAdjustment = await QBOInventoryAdjustment.itemAdjustmentFromParts(
    //     [{ ...partState, adjustmentQuantity: partState.QtyOnHand - qboRecord?.QtyOnHand || 0 }],
    //     null,
    //     partState.InvStartDate ? new Date(partState.InvStartDate) : new Date(),
    //     `Part quantity updated manually by ${currentUser.email.split('@')[0]}`,
    //   );
    //   // Finally, create the inventory adjustment
    //   await QBOInventoryAdjustment.create(inventoryAdjustment);
    // }

    setSaveText('Saving to HELM...');
    await firestore.collection(inventoryDbString).doc(partState.Sku).set({
      ...partState,
      SyncToken: updated?.SyncToken || partState.SyncToken || '0',
      Id: updated?.Id || partState.Id || '',
      MetaData: { ...partState.MetaData, LastUpdatedTime: qboDateString(new Date(), true) },
    });

    if (partState.QtyOnHand <= partState.reorderQty && partState.reorderQty > 0) {
      // send message to Purchasing group
      reorderPointNotification(database, messageDbString, reorderItemNotificationUsers, partState);
    }

    if (partState.Parent) {
      await saveChild({ ...partState, Id: qboRecord?.Id || '' });
      await updateParent();
      finishSave();
    } else {
      finishSave();
    }
  };

  return (
    <>
      {isSaving ? (
        <SpinnerWrapper>
          <SaveSpinner />
          <SaveText>{saveText}</SaveText>
        </SpinnerWrapper>
      ) : (
        <SaveButton id="inventory-record-save-button" type="primary" onClick={onSave} test={useTestData}>Save</SaveButton>
      )}
    </>
  );
};
